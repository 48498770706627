import React from "react";

export default [
  {
    id: 1,
    categoryTitle:
      "Commande et livraison de traitements avec une pharmacie partenaire",
    sections: [
      {
        title: (
          <h3>
            Comment se passe la commande en ligne dans une pharmacie
            partenaire ?
          </h3>
        ),
        body: (
          <>
            <p>
              Depuis votre espace personnel une fois votre téléconsultation
              réalisée et votre e-ordonnance délivrée par le médecin (s’il
              estime que vous avez besoin d’un traitement), vous pourrez accéder
              à l’espace de réservation de médicament en ligne de la pharmacie
              partenaire choisie.
            </p>
            <p>
              La pharmacie préparera votre réservation et la remettra dans un
              packaging scellé et discret avant de la transmettre au mandataire
              pour expédition.
            </p>
            <p>
              Vous pouvez également choisir de télécharger l’ordonnance pour
              retirer le traitement dans la pharmacie de votre choix.
            </p>
          </>
        ),
      },
      {
        title: (
          <h3>
            Est-ce que je peux commander des médicaments avec ordonnance dans
            plusieurs pharmacies à la fois ?
          </h3>
        ),
        body: (
          <p>
            Non, chaque délivrance doit se faire en choisissant une pharmacie
            spécifique.
          </p>
        ),
      },
      {
        title: (
          <h3>
            Comment ma commande est-elle expédiée ? Quel est le délai de
            livraison ?
          </h3>
        ),
        body: (
          <p>
            Les commandes sont expédiées dans des boîtes discrètes, à
            l’intérieur desquelles vous retrouverez les boîtes de médicaments
            préparées par la pharmacie. Votre commande est expédiée via
            Colissimo et arrive dans un délai de 48 à 72 heures, un service de
            livraison express avec DHL pour une livraison en 24 à 36 heures est
            également disponible.
          </p>
        ),
      },
      {
        title: <h3>Comment s’effectue le paiement ?</h3>,
        body: (
          <>
            <p>
              Les paiements, pour le règlement des téléconsultations et des
              réservations de traitements, peuvent s’effectuer par carte
              bancaire ou paypal.{" "}
            </p>
            <p>
              En ce qui concerne les téléconsultations, un pré-règlement vous
              sera demandé.
            </p>
          </>
        ),
      },
      {
        title: <h3>Est-il possible de se faire livrer à l’étranger ?</h3>,
        body: (
          <p>
            Il est possible de se faire livrer les phytothérapies recommandées
            par les médecins. En revanche, vous ne pouvez pas vous faire livrer
            à l’étranger vos réservations de médicaments effectuées à partir de
            Charles.
          </p>
        ),
      },
      {
        title: (
          <h3>
            Qui verra les médicaments que j’ai réservés ? Seront-ils visibles
            par le livreur ?
          </h3>
        ),
        body: (
          <p>
            Les médicaments seront uniquement vus par le pharmacien qui
            préparera la réservation. Ils seront remis dans un emballage scellé
            et discret au livreur.
          </p>
        ),
      },
    ],
  },
  {
    id: 2,
    categoryTitle: "Téléconsultation avec nos médecins partenaires",
    sections: [
      {
        title: (
          <h3>Comment télé-consulter un médecin sexologue sur Charles ?</h3>
        ),
        body: (
          <>
            <p>Pour télé-consulter un médecin sur Charles vous devez :</p>
            <ol>
              <li>Remplir le formulaire en ligne accessible ici</li>
              <li>
                Planifier votre RDV qui se fera au choix par messagerie
                sécurisée, par téléphone ou par visioconférence.
              </li>
              <li>
                Confirmer votre RDV en renseignant vos informations personnelles
                et en pré-réglant la téléconsultation sur la plateforme.
              </li>
              <li>
                Une fois la consultation terminée, RDV dans votre espace
                personnel Charles pour consulter votre dossier médical et
                l’ordonnance du médecin le cas échéant.
              </li>
            </ol>
          </>
        ),
      },
      {
        title: (
          <h3>Comment décaler ou annuler une consultation sur Charles ?</h3>
        ),
        body: (
          <p>
            Vous pouvez décaler ou annuler une consultation depuis votre espace
            personnel. En cas d’annulation plus de 24h avant l’heure du RDV, le
            pré-règlement vous sera restitué sur le compte de la carte bancaire
            utilisée lors du paiement.
          </p>
        ),
      },
      {
        title: (
          <h3>Est-il possible de télé-consulter depuis un pays étranger ?</h3>
        ),
        body: (
          <p>
            Oui, vous pouvez télé-consulter depuis n’importe quel pays au choix
            par messagerie sécurisée, appel téléphonique ou visioconférence.
          </p>
        ),
      },
      {
        title: <h3>Comment me préparer au mieux pour ma consultation ?</h3>,
        body: (
          <>
            <p>
              Pour bénéficier de la meilleure expérience de téléconsultation
              possible, voici nos conseils :
            </p>
            <ul>
              <li>
                Se placer dans un endroit calme et disposant d’une connexion
                internet de qualité
              </li>
              <li>
                Préparer les informations personnelles à communiquer au médecin
                ainsi que les éléments médicaux qui pourraient l’aider lors de
                votre prise en charge.
              </li>
            </ul>
          </>
        ),
      },
      {
        title: (
          <h3>
            Que se passe-t-il si le médecin sur Charles ne peut pas m’aider ?
          </h3>
        ),
        body: (
          <p>
            La téléconsultation ne suffit pas toujours à remplacer une
            consultation en cabinet. Si votre médecin sur Charles considère que
            c’est le cas, il n&apos;hésitera pas à vous orienter vers un autre
            lieu de soins.
          </p>
        ),
      },
      {
        title: (
          <h3>Est-ce possible de réserver pour quelqu’un d’autre que moi ?</h3>
        ),
        body: (
          <p>
            Non, il n’est pas possible de réserver pour quelqu’un d’autre que
            vous. Votre compte Charles est strictement personnel.
          </p>
        ),
      },
      {
        title: <h3>C&apos;est une urgence, que dois-je faire ?</h3>,
        body: (
          <p>
            Charles n’est pas une plateforme d’urgence. En cas d&apos;urgence,
            veuillez composer le 15 ou le 112 (numéro d’urgence européen)
            immédiatement.
          </p>
        ),
      },
      {
        title: (
          <h3>Combien coûte la consultation avec un médecin sur Charles ?</h3>
        ),
        body: (
          <>
            <p>
              Les médecins présents sur la plateforme Charles proposent une
              consultation d’une quinzaine de minutes à 35 euros.
            </p>
          </>
        ),
      },
      {
        title: (
          <h3>
            Est-ce que je vais recevoir une ordonnance ? Comment l’utiliser ?
          </h3>
        ),
        body: (
          <>
            <p>
              Une fois la téléconsultation terminée, si le médecin juge
              nécessaire de vous prescrire un traitement, vous recevrez une
              e-ordonnance.
            </p>
            <p>
              Un email vous confirmera la disponibilité de l’e-ordonnance
              quelques minutes après votre téléconsultation.
            </p>
            <p>
              Elle sera accessible dans votre espace personnel sécurisé, vous
              pourrez la consulter, la télécharger pour retirer votre ordonnance
              en pharmacie, ou la transmettre à une pharmacie partenaire pour
              vous faire livrer à domicile.
            </p>
          </>
        ),
      },
      {
        title: (
          <h3>
            Tout le monde peut-il bénéficier d’une téléconsultation sur
            Charles ?
          </h3>
        ),
        body: (
          <p>
            Pour bénéficier d’une téléconsultation sur Charles, vous devez être
            une personne adulte.
          </p>
        ),
      },
      {
        title: <h3>Qui sont les médecins sur la plateforme Charles ?</h3>,
        body: (
          <>
            <p>
              Tous les médecins de la plateforme Charles sont des médecins
              diplômés en France, exerçant également en cabinet, à l'hôpital ou
              en clinique. En plus des médecins présents sur la plateforme,
              Charles est appuyé par un Comité Scientifique qui évalue et
              encadre l’activité de Charles sur le plan des pratiques et de la
              déontologie, en toute indépendance et en toute transparence.
            </p>
            <p>
              Ce comité scientifique est composé de médecins andrologues,
              urologues et sexologues, dermatologues et somnologues, de
              professeurs d’université et de chef de service hospitalier.
            </p>
          </>
        ),
      },
      {
        title: (
          <h3>
            Est-il nécessaire d’effectuer la téléconsultation sous forme de
            visioconférence ?
          </h3>
        ),
        body: (
          <p>
            Non. Vous avez également la possibilité de télé-consulter sous forme
            de chat sur une messagerie sécurisée ou d’appel téléphonique.
          </p>
        ),
      },
      {
        title: (
          <h3>La consultation est-elle remboursée par la sécurité sociale ?</h3>
        ),
        body: (
          <>
            <p>
              La téléconsultation de médecins sur Charles n’est pas encore
              remboursée par la sécurité sociale. Cependant, nous sommes
              convaincus que la santé connectée est absolument clef pour la
              santé en général et nous militons activement pour que la
              téléconsultation soit plus largement remboursée.
            </p>
            <p>
              L’assurance maladie ne rembourse les téléconsultations que si les
              deux conditions suivantes sont remplies : 1. Le médecin
              téléconsultant est votre médecin traitant ou a été désigné par
              votre médecin traitant. 2. Vous avez effectué une consultation
              “physique” avec le médecin téléconsultant dans les 12 derniers
              mois. Les consultations sur Charles ne remplissent aucune de ces
              deux conditions.
            </p>
          </>
        ),
      },
    ],
  },
  {
    id: 3,
    categoryTitle: "Confidentialité et Sécurité",
    sections: [
      {
        title: <h3>Mes données personnelles sont-elles en sécurité ?</h3>,
        body: (
          <p>
            Nous prenons votre vie privée et la sécurité de vos données très au
            sérieux. Nous avons conçu la plateforme Charles pour nous conformer
            à toutes les lois en vigueur en matière de confidentialité et avons
            mis en place des protocoles de sécurité stricts pour protéger vos
            données.
          </p>
        ),
      },
      {
        title: (
          <h3>
            Qui consulte mes données de santé et comment sont-elles stockées et
            protégées ?
          </h3>
        ),
        body: (
          <>
            <p>
              La confidentialité de vos données de santé est absolument clé pour
              Charles et nous mettons tout en oeuvre pour la protéger.
            </p>
            <ol>
              <li>
                Accès à votre espace personnel par double authentification :
                suite à la saisie de votre adresse mail et de votre mot de
                passe, un SMS d’authentification vous sera envoyé.
              </li>
              <li>
                Sécurisation de données médicales : Pour garantir la sécurité,
                la confidentialité et l’intégrité de vos données de santé,
                Charles les confie à un Hébergeur Agréé Données de Santé (HADS)
                auprès de l’Agence Française de la Santé Numérique (ASIP).
              </li>
              <li>
                Vos informations sont accessibles uniquement aux personnes
                habilitées L’accès aux données de santé des patients Charles est
                régulé. Seuls les médecins et les pharmaciens habilités auront
                accès à vos données.
              </li>
            </ol>
          </>
        ),
      },
      {
        title: <h3>Comment sont sécurisées mes informations bancaires ?</h3>,
        body: (
          <>
            <p>
              Pour garantir la sécurité de vos informations bancaires, Charles
              s’appuie sur la solution MANGOPAY qui est conforme aux normes
              PSD2, GDPR et PCI DSS. Vos coordonnées bancaires ne sont pas
              conservées par Charles mais par MANGOPAY qui en assure la
              sécurité.
            </p>
          </>
        ),
      },
      {
        title: (
          <h3>
            Une téléconsultation de médecin sur Charles peut-elle remplacer mon
            médecin traitant ?
          </h3>
        ),
        body: (
          <p>
            La téléconsultation de médecin sur Charles ne remplace pas votre
            médecin traitant. Il est donc important de tenir votre Médecin
            traitant au courant de vos téléconsultations.{" "}
          </p>
        ),
      },
      {
        title: (
          <h3>La télémédecine est-elle un moyen sûr d’être pris en charge ?</h3>
        ),
        body: (
          <>
            <p>
              Oui, la télémédecine est sûre et efficace tant que les patients
              répondent aux questions de façon honnête, précise et complète. Il
              est important de fournir des informations exactes et à jour dans
              le questionnaire préliminaire et pendant la téléconsultation.
              Après la consultation, il est crucial de suivre la posologie
              indiquée par votre médecin dans l’ordonnance (si le médecin a jugé
              nécessaire de vous en prescrire une).
            </p>
            <p>
              Par ailleurs, un système de messagerie sécurisée dans votre espace
              personnel permet de poser des questions complémentaires à votre
              médecin et votre pharmacien (si vous avez réservé à partir de
              Charles chez un pharmacien partenaire).
            </p>
          </>
        ),
      },
      {
        title: <h3>Est-il légal de délivrer des ordonnances en ligne ?</h3>,
        body: (
          <p>
            Oui. Charles a mis au point des technologies sécurisées pour
            permettre aux médecins de pratiquer des téléconsultations et de
            délivrer des e-ordonnances.{" "}
          </p>
        ),
      },
      {
        title: <h3>Mes consultations sont-elles privées ?</h3>,
        body: (
          <p>
            Toutes les téléconsultations sur la plateforme Charles sont
            effectués dans le plus strict respect du secret médical.
          </p>
        ),
      },
    ],
  },
];
